import { cloneDeep } from "lodash";

import AllTabsWhite from "@shared/light-ray/assets/svg/entity-details/all_tabs_white.svg";
import CircleCheck from "@shared/light-ray/assets/svg/entity-details/circle_check.svg";
import CircleQuestion from "@shared/light-ray/assets/svg/entity-details/circle_question.svg";
import CircleCross from "@shared/light-ray/assets/svg/entity-details/circle_cross.svg";
import CircleMinusIcon from "@shared/light-ray/assets/svg/entity-details/circle_minus.svg";

export const tabsModel = {
    selected: "all",
    list: [
        {
            id: "all",
            label: "All",
            isIcon: true,
            iconSvgName: AllTabsWhite,
            isCount: true,
            counts: 0,
        },
        {
            id: "confirmed",
            label: "Confirmed",
            isIcon: true,
            iconSvgName: CircleCheck,
            isCount: true,
            counts: 0,
        },
        {
            id: "potential",
            label: "Potential",
            isIcon: true,
            iconSvgName: CircleQuestion,
            isCount: true,
            counts: 0,
        },
        {
            id: "irrelevant",
            label: "Irrelevant",
            isIcon: true,
            iconSvgName: CircleCross,
            isCount: true,
            counts: 0,
        },
        {
            id: "unknown",
            label: "Unknown",
            isIcon: true,
            iconSvgName: CircleMinusIcon,
            isCount: true,
            counts: 0,
        },
        // {
        //     id: "creditSource",
        //     label: "Credit & Source",
        // }
    ],
}


export const lexisBridgerTabs = {
    tabsModel: cloneDeep(tabsModel),
    hiddenTabs: ['potential', 'irrelevant'],
}

export const companyIdentifierTabs = {
    tabsModel: cloneDeep(tabsModel),
    hiddenTabs: ['potential', 'irrelevant']
}

export const searchEngineTabs = {
    tabsModel: cloneDeep(tabsModel),
    hiddenTabs: []
}

export const vlexTabs = {
    tabsModel: cloneDeep(tabsModel),
    hiddenTabs: []
};